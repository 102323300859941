.audioRecording {
  /* Customize the audio player here */
  background-color: #e8f4fd !important;
}

.rhap_container {
  background-color: #1b1bd0;
}
.rhap_button-clear {
  color: #0d47a1 !important;
}

.rhap_progress-indicator,
.rhap_volume-indicator {
  background-color: #0d47a1 !important;
}
.rhap_volume-bar,
.rhap_progress-filled {
  background-color: #9eb5d9 !important;
}
.amplify-button--primary {
  background-color: #0d47a1 !important;
}
/* .rhap_main {
  background-color: #c64747;
}

.rhap_progress-filled {
  background: #c64747;
}

.rhap_button-clear {
  color: #c64747;
} */
